import React from "react"

import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import LogoCarousel from "../components/logo_carousel"
import { graphql, useStaticQuery } from "gatsby"

const AboutMePage = () => {
  const data = useStaticQuery(graphql`
    query CountJobs {
      allWorksJson {
        totalCount
        edges {
          node {
            awards
          }
        }
      }
      desktop: file(relativePath: { eq: "images/about_bg.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      signature: file(relativePath: { eq: "images/signature.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      me: file(relativePath: { eq: "images/me.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  let awardsSum = 0
  data.allWorksJson.edges.forEach(i => {
    let total = i.node.awards.split(",").length
    if (total === 1) {
      if (i.node.awards.length > 0) total = 1
      else total = 0
    }
    awardsSum += total
  })
  const backgroundFluidImageStack = [
    data.desktop.childImageSharp.fluid,
    ` linear-gradient( rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55) )`,
  ].reverse()
  return (
    <Layout>
      <SEO
        title="About Me"
        description="Find out everything about Christian Bianchini"
      />

      <div class="about-us-area ptb-90">
        <div class="container">
          <div class="area-title text-center">
            <h2>About Me</h2>
            <p>
              Inspired by new technology around me and continuously evolving of
              our world.
            </p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="about-text mt-90">
                <h5>Hello, I'm Christian</h5>
                <p>
                  Creating immersive experience is my key role in the business
                  which covers: brainstorming, prototyping and execution. Always
                  looking for new challenges and the use of new technologies to
                  achieve the impossible and deliver breath-taking outcomes.
                </p>
                <p>
                  My experience allows me to expand my mind to think out of the
                  box and make critical decisions in a few moments. I look after
                  my team in my tech lead role and am happy to work together
                  like a well-oiled machine.
                </p>
                <ul>
                  <li>
                    <abbr title="Phone">P:</abbr> (44) 7507591710
                  </li>
                  <li>
                    E-mail:
                    <a href="mailto:christian@bianchini.ch">
                      christian@bianchini.ch
                    </a>
                  </li>
                </ul>
                <div class="signature mt-20" style={{ maxWidth: "300px" }}>
                  {/*<Image filename="images/signature.png"> </Image>*/}
                  <Img
                    fluid={data.signature.childImageSharp.fluid}
                    alt="signature"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="about-img hidden-sm">
                <Img fluid={data.me.childImageSharp.fluid} alt="me" />

                {/*<Image filename="images/me.jpg"></Image>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      <BackgroundImage
        Tag="section"
        className="basic-counter-area ptb-150 bg-black-opacity fix"
        fluid={backgroundFluidImageStack}
        style={{
          opacity: ".18%",
        }}
      >
        <div className="counter-box">
          <div className="counter-icon">
            <span className=" icon-trophy"></span>
          </div>
          <div className="counter-text">
            <h3 className="counter-number">234</h3>
            <h4>cups of coffee</h4>
          </div>
        </div>
        <div className="counter-box">
          <div className="counter-icon">
            <span className="icon-happy"></span>
          </div>
          <div className="counter-text">
            <h3 className="counter-number">{data.allWorksJson.totalCount}</h3>
            <h4>project done</h4>
          </div>
        </div>
        <div className="counter-box">
          <div className="counter-icon">
            <span className="icon-alarmclock"></span>
          </div>
          <div className="counter-text">
            <h3 className="counter-number">46</h3>
            <h4>branding</h4>
          </div>
        </div>
        <div className="counter-box">
          <div className="counter-icon">
            <span className="icon-megaphone"></span>
          </div>
          <div className="counter-text">
            <h3 className="counter-number">{awardsSum}</h3>
            <h4>awards won</h4>
          </div>
        </div>
      </BackgroundImage>

      <div className="clients-area ptb-40">
        <div className="container">
          <div className="row">
            <LogoCarousel />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutMePage
