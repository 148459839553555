import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"

const LogoCarousel = props => (
  <StaticQuery
    query={graphql`
      query LogoCarousel {
        allCarouselItemsJson {
          edges {
            node {
              name
              image {
                childImageSharp {
                  fluid(maxWidth: 100, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        autoplay: true,
        cssEase: "linear",
      }
      return (
        <Slider {...settings}>
          {data.allCarouselItemsJson.edges.map(i => {
            return (
              <div>
                <Img
                  style={{
                    maxWidth: "120px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  alt={i.node.name}
                  fluid={i.node.image.childImageSharp.fluid}
                />
              </div>
            )
          })}
        </Slider>
      )
    }}
  />
)

export default LogoCarousel
